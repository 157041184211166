import store from '@/store'

export function calcFirstLeaseRent(): number {
  let firstLeaseRent = 10;

  return Math.round(firstLeaseRent * 100) / 100;
}

export function calcFirstLeaseCharge(): number {
  let firstLeaseCharge = 10;

  return Math.round(firstLeaseCharge * 100) / 100;
}

export function calcLeaseRent(): number {
  let leaseRent = 10;

  return Math.round(leaseRent * 100) / 100;
}

export function calcLeaseCharge(): number {
  let leaseCharge = 10;

  return Math.round(leaseCharge * 100) / 100;
}

export function calcSubTotalHt(a, b): number {
  let subtotalHt = 0;
  subtotalHt = Number(a) + Number(b);
  return Math.round(subtotalHt * 100) / 100;
}

export function calcTav(a, aTav, b, bTav): number {
  let tavRent = 0;
  let tavCharge = 0;
  tavRent = a * (aTav.value == 1 ? 0.20 : 0);
  tavCharge = b * (bTav.value == 1 ? 0.20 : 0);
  return Math.round((tavRent + tavCharge) * 100) / 100;
}

export function calcTotalTtc(a, b): number {
  let total = 0;
  total = Number(a) + Number(b);
  return Math.round(total * 100) / 100;
}