<template>
  <div> 
    <div v-if="isLoadingLease" class="content-loader-center m-0 h-100">
      <div class="text-center flex-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement du formulaire...
      </div>
    </div>
    <b-row v-else class="content-scrollable-sticky m-0 h-100 justify-content-center" >
      <b-col cols="3" id="nav-lease">
        <ul>
          <li>
            <a href="#property" class="current d-flex align-center align-content-center align-items-center  w-100">
              <span class="material-icons-outlined text-primary">
                corporate_fare
              </span>
              <span class="pl-1">Le bien</span>
            </a>
          </li>
          <li>
            <a href="#contact" class="d-flex align-center align-content-center align-items-center  w-100">
              <span class="material-icons-outlined text-primary">
                assignment
              </span>
              <span class="pl-1">Coordonnées</span>
            </a>
          </li>
          <li>
            <a href="#legal" class="d-flex align-center align-content-center align-items-center  w-100">
              <span class="material-icons-outlined text-primary">
                euro_symbol
              </span>
              <span class="pl-1">Juridique & Financier</span>
            </a>
          </li>
          <!-- <li>
            <a href="#documents" class="d-flex align-center align-content-center align-items-center  w-100">
              <span class="material-icons-outlined text-primary">
                inbox
              </span>
              <span class="pl-1">Documents</span>
            </a>
          </li> -->
        </ul>
      </b-col> 
      <b-col cols="9" class="content-scrollable-sticky-main pr-2">

        <validation-observer ref="formCreateLease">
          <b-form autocomplete="off" >
            <b-row class="my-0"> 
              <b-col
                class="content-header"
                cols="12"
              >
                <h2 class="content-header-title float-left m-0 pr-1 mr-1">
                  {{ $route.meta.pageTitle }}
                </h2>
                <div class="content-header-search mr-1">
                  <h3 class="m-0">{{leaseForm.label}}</h3>
                </div>
                <div class="content-header-actions d-flex">
                    <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
                </div>
              </b-col>
            </b-row> 

            <!-- <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
            </div> -->

            <div id="property" class="pt-0">
              <b-row class="my-0">
                <b-col cols="8" class="py-0 mb-1">
                  <b-form-group label="Nom de dossier *" label-for="label">
                    <validation-provider
                      #default="{ errors }"
                      name="Nom du dossier"
                      rules="required"
                    >
                      <b-form-input
                        id="label"
                        v-model="leaseForm.label"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Renseigner un nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="d-flex py-0 mb-1">
                  <b-form-group label="Code CRO" label-for="codeCRO" class="pr-1" style="flex:1">
                    <validation-provider #default="{ errors }" name="Code CRO">
                      <b-form-input
                        id="codeCRO"
                        v-model="leaseForm.codeCRO"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0"> 
                <b-col cols="12" class="py-0 mb-1">
                  <h3>Adresse du bien</h3>
                  <label label-for="address" style="font-weight: 600;">Adresse</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="propertyAddress">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="propertyAddress"
                          v-model="leaseForm.propertyAddress"
                          :ieCloseFix="false"
                          :data="autocompletePropertyData"
                          :serializer="item => item.properties.label"
                          :showAllResults="true"
                          placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                          @input="autocompleteAddressSource()"
                          @hit="autocompleteAddressSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        >
                          <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="item"> 
                              <small>{{data.properties.name}}</small> 
                              <span>{{data.properties.postcode+ ' ' + data.properties.city}}</span> 
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="propertyAddressComplement"
                  >
                    <b-form-input
                      id="propertyAddressComplement"
                      v-model="leaseForm.propertyAddressComplement"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Batiment, Appartement.."
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="propertyZipCode">
                    <b-form-input
                      id="propertyZipCode"
                      v-model="leaseForm.propertyZipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code postal"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
                <b-col cols="9" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="propertyCity">
                    <b-form-input
                      id="propertyCity"
                      v-model="leaseForm.propertyCity"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ville"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="propertyCountry">
                    <b-form-input
                      id="propertyCountry"
                      v-model="leaseForm.propertyCountry"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pays"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label for="address'">Coordonnées GPS</label>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="latitude">
                        <b-form-input class="mb-0" id="latitude" v-model="leaseForm.propertyLatitude" placeholder="Latitude"></b-form-input>
                    </validation-provider>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="longitude">
                        <b-form-input class="mb-0" id="longitude" v-model="leaseForm.propertyLongitude" placeholder="Longitude"></b-form-input>
                    </validation-provider>
                </b-col>
              </b-row> 
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label label-for="address" style="font-weight: 600;">Description du bien</label>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="propertyType">Type de bien</label>
                    <validation-provider #default="{ errors }" name="periodicity" 
                      rules="required">
                      <v-select
                        :clearable="false"
                        :deselectFromDropdown="true"
                        :closeOnSelect="true"
                        :multiple="false"
                        id="propertyType"
                        :state="errors.length > 0 ? false : null"
                        v-model="leaseForm.propertyTypeId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          propertyTypeList.map(elem => {
                            return { label: elem.label, value: elem.id }
                          })"
                        :reduce="elem => elem.value"
                      >
                        <template v-slot:no-options>
                          <template> Aucun type de bien trouvé </template>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="propertyArea">
                      <label label-for="propertyType">Surface en m<sup>2</sup></label>
                      <b-form-input class="mb-0" id="propertyArea" v-model="leaseForm.propertyArea" placeholder="Surface m2"></b-form-input>
                    </validation-provider>
                </b-col>
              </b-row> 

              

              <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>
            </div>
            <div id="contact" class="pt-1">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <h3>Bailleur / Propriétaire du bien</h3>
                  <b-form-group>
                    <label label-for="searchLandlord" style="font-weight: 600;">Société / Particulier</label>
                    <validation-provider #default="{ errors }" name="searchLandlord">
                      <!-- <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="propertyAddress"
                          v-model="leaseForm.propertyAddress"
                          :ieCloseFix="false"
                          :data="autocompletePropertyData"
                          :serializer="item => item.properties.label"
                          :showAllResults="true"
                          placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                          @input="autocompleteAddressSource()"
                          @hit="autocompleteAddressSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        > -->

                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="searchLandlord"
                          inputClass="bg-light-primary"
                          v-model="searchLandlord"
                          :ieCloseFix="false"
                          :data="autocompleteLandlordData"
                          :serializer="item => item.Fields[0].Value"
                          :showAllResults="true"
                          placeholder="Rechercher par code analytique"
                          @input="autocompleteLandlord()"
                          @hit="autocompleteLandlordSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        >
                          <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="item"> 
                              <span>{{data.Fields[0].Value}}</span> 
                              <small>{{data.Fields[1].Value}} - {{data.Fields[2].Value+ ' ' + data.Fields[3].Value}}</small> 
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="landlordName"
                    >
                      <b-form-input
                        id="landlordName"
                        v-model="leaseForm.landlordName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0"> 
                <b-col cols="12" class="py-0 mb-1">
                  <label label-for="address" style="font-weight: 600;">Adresse</label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="landlordAddress"
                    >
                      <b-form-input
                        id="landlordAddress"
                        v-model="leaseForm.landlordAddress"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Rue et n° de rue"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="landlordAddressComplement"
                  >
                    <b-form-input
                      id="landlordAddressComplement"
                      v-model="leaseForm.landlordAddressComplement"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Batiment, Appartement.."
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="landlordZipCode">
                    <b-form-input
                      id="landlordZipCode"
                      v-model="leaseForm.landlordZipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code postal"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
                <b-col cols="9" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="landlordCity">
                    <b-form-input
                      id="landlordCity"
                      v-model="leaseForm.landlordCity"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ville"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="landlordCountry">
                    <b-form-input
                      id="landlordCountry"
                      v-model="leaseForm.landlordCountry"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pays"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label for="address'">Coordonnées GPS</label>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="latitude">
                        <b-form-input class="mb-0" id="latitude" v-model="leaseForm.landlordLatitude" placeholder="Latitude"></b-form-input>
                    </validation-provider>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="longitude">
                        <b-form-input class="mb-0" id="longitude" v-model="leaseForm.landlordLongitude" placeholder="Longitude"></b-form-input>
                    </validation-provider>
                </b-col>
              </b-row> 
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <label label-for="searchLandlordContract" style="font-weight: 600;">Contact principal</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="searchLandlordContract">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="searchLandlordContract"
                          inputClass="bg-light-primary"
                          v-model="searchLandlordContract"
                          :ieCloseFix="false"
                          :data="autocompleteLandlordContractData"
                          :serializer="item => item.Fields[0].Value"
                          :showAllResults="true"
                          placeholder="Rechercher par code analytique"
                          @input="autocompleteLandlordContract()"
                          @hit="autocompleteLandlordContractSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        >
                          <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="item"> 
                              <span>{{data.Fields[0].Value}}</span> 
                              <small>{{data.Fields[1].Value}} - {{data.Fields[2].Value+ ' ' + data.Fields[3].Value}}</small> 
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="landlordContractLastName"
                    >
                      <b-form-input
                        id="landlordContractLastName"
                        v-model="leaseForm.landlordContractLastName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="landlordContractFirstName"
                    >
                      <b-form-input
                        id="landlordContractFirstName"
                        v-model="leaseForm.landlordContractFirstName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Prénom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                    <b-form-group label-for="landlordContractPhone">
                        <b-form-input id="landlordContractPhone" v-model="leaseForm.landlordContractPhone" v-mask="'## ## ## ## ##'" autocomplete="nope" aria-autocomplete="nope" placeholder="Téléphone"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                    <b-form-group label-for="landlordContractEmail">
                        <validation-provider #default="{ errors }" name="landlordContractEmail" rules="email">
                            <b-form-input id="landlordContractEmail" v-model="leaseForm.landlordContractEmail" :state="errors.length &gt; 0 ? false : null" autocomplete="nope" aria-autocomplete="nope"  placeholder="Adresse e-mail"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
              </b-row>
              <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <h3>Locataire du bien</h3>
                  <b-form-group>
                    <label label-for="searchTenant" style="font-weight: 600;">Société / Particulier</label>
                    <validation-provider #default="{ errors }" name="searchTenant">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="searchTenant"
                          inputClass="bg-light-primary"
                          v-model="searchTenant"
                          :ieCloseFix="false"
                          :data="autocompleteTenantData"
                          :serializer="item => item.Fields[0].Value"
                          :showAllResults="true"
                          placeholder="Rechercher par code analytique"
                          @input="autocompleteTenant()"
                          @hit="autocompleteTenantSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        >
                          <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="item"> 
                              <span>{{data.Fields[0].Value}}</span> 
                              <small>{{data.Fields[1].Value}} - {{data.Fields[2].Value+ ' ' + data.Fields[3].Value}}</small> 
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="tenantName"
                    >
                      <b-form-input
                        id="tenantName"
                        v-model="leaseForm.tenantName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0"> 
                <b-col cols="12" class="py-0 mb-1">
                  <label label-for="address" style="font-weight: 600;">Adresse</label>
                  <b-form-group label-for="address">
                    <validation-provider
                      #default="{ errors }"
                      name="tenantAddress"
                    >
                      <b-form-input
                        id="tenantAddress"
                        v-model="leaseForm.tenantAddress"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Rue et n° de rue"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="tenantAddressComplement"
                  >
                    <b-form-input
                      id="tenantAddressComplement"
                      v-model="leaseForm.tenantAddressComplement"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Batiment, Appartement.."
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="tenantZipCode">
                    <b-form-input
                      id="tenantZipCode"
                      v-model="leaseForm.tenantZipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code postal"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
                <b-col cols="9" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="tenantCity">
                    <b-form-input
                      id="tenantCity"
                      v-model="leaseForm.tenantCity"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ville"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="tenantCountry">
                    <b-form-input
                      id="tenantCountry"
                      v-model="leaseForm.tenantCountry"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Pays"
                      class="not-autocomplete"
                      autocomplete="nope" aria-autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label for="address'">Coordonnées GPS</label>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="latitude">
                        <b-form-input class="mb-0" id="latitude" v-model="leaseForm.tenantLatitude" placeholder="Latitude"></b-form-input>
                    </validation-provider>
                </b-col>
                <b-col cols="3" class="py-0 mb-1">
                    <validation-provider name="longitude">
                        <b-form-input class="mb-0" id="longitude" v-model="leaseForm.tenantLongitude" placeholder="Longitude"></b-form-input>
                    </validation-provider>
                </b-col>
              </b-row> 
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <label label-for="searchTenantContract" style="font-weight: 600;">Contact principal</label>
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="searchTenantContract">
                        <vue-typeahead-bootstrap
                          type="text"
                          :state="errors.length &gt; 0 ? false : null"
                          class="not-autocomplete" id="searchTenantContract"
                          inputClass="bg-light-primary"
                          v-model="searchTenantContract"
                          :ieCloseFix="false"
                          :data="autocompleteTenantContractData"
                          :serializer="item => item.Fields[0].Value"
                          :showAllResults="true"
                          placeholder="Rechercher par code analytique"
                          @input="autocompleteTenantContract()"
                          @hit="autocompleteTenantContractSelected"
                          autocomplete="nope" aria-autocomplete="nope"
                        >
                          <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <div class="item"> 
                              <span>{{data.Fields[0].Value}}</span> 
                              <small>{{data.Fields[1].Value}} - {{data.Fields[2].Value+ ' ' + data.Fields[3].Value}}</small> 
                            </div>
                          </template>
                        </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="tenantContractLastName"
                    >
                      <b-form-input
                        id="tenantContractLastName"
                        v-model="leaseForm.tenantContractLastName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="tenantContractFirstName"
                    >
                      <b-form-input
                        id="tenantContractFirstName"
                        v-model="leaseForm.tenantContractFirstName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Prénom"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                    <b-form-group label-for="tenantContractPhone">
                        <b-form-input id="tenantContractPhone" v-model="leaseForm.tenantContractPhone" v-mask="'## ## ## ## ##'" autocomplete="nope" aria-autocomplete="nope" placeholder="Téléphone"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                    <b-form-group label-for="tenantContractEmail">
                        <validation-provider #default="{ errors }" name="tenantContractEmail" rules="email">
                            <b-form-input id="tenantContractEmail" v-model="leaseForm.tenantContractEmail" :state="errors.length &gt; 0 ? false : null" autocomplete="nope" aria-autocomplete="nope"  placeholder="Adresse e-mail"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
              </b-row>
              <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>
            </div>
            <div id="legal" class="pt-1">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1"> 
                  <h3>A propos du bail</h3>
                  <b-form-group>
                    <label label-for="searchTenant" style="font-weight: 600;">Type de bail</label>
                    <validation-provider #default="{ errors }" name="leaseType" 
                      rules="required">
                      <v-select
                        :deselectFromDropdown="true"
                        :closeOnSelect="true"
                        :multiple="false"
                        id="leaseType"
                        :state="errors.length > 0 ? false : null"
                        v-model="leaseForm.leaseTypeId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          leaseTypeList.map(elem => {
                            return { label: elem.label, value: elem.id }
                          })"
                        :reduce="elem => elem.value"
                      >
                        <template v-slot:no-options>
                          <template> Aucun type de bail trouvé </template>
                        </template>
                        <!-- <li class="border-bottom p-1 py-50" slot="list-header">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            @click="newCollaborator()"
                            variant="primary"
                            size="sm"
                            class="w-100 d-flex justify-content-center align-items-center"
                          >
                            + Nouveau type de bail
                            <span class="link_icon material-icons-outlined text-white m-0 ml-50" style="font-size:13px">
                              open_in_new
                            </span>
                          </b-button>
                        </li> -->
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="startDate" style="font-weight: 600;">Début du bail</label>
                    <validation-provider
                      #default="{ errors }"
                      name="startDate"
                    >
                      <date-range-picker

                        :single-date-picker="true"
                        :show-week-numbers="false"
                        v-model="startDate"

                        ref="pickerStartDate"
                        id="startDate"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        @update="setStartDate"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        :auto-apply="true"
                      >
                      </date-range-picker>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="endDate" style="font-weight: 600;">Fin du bail</label>
                    <validation-provider
                      #default="{ errors }"
                      name="endDate"
                    >
                      <date-range-picker
                        ref="pickerEndDate"
                        id="endDate"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        @update="setEndDate"
                        :show-week-numbers="false"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="endDate"
                        :single-date-picker="true"
                        :auto-apply="true"
                      >
                      </date-range-picker>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="duration"
                    >
                      <label label-for="duration" style="font-weight: 600;">Durée</label>
                      <b-form-input
                        id="duration"
                        v-model="leaseDuration"
                        :state="errors.length > 0 ? false : null"
                        placeholder="6 ans"
                        class="not-autocomplete"
                        autocomplete="nope" aria-autocomplete="nope"
                        disabled
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1">
                  <b-row class="my-0">
                    <b-col cols="9" class="py-0 mb-1">
                      <b-form-group>
                        <label label-for="periodicity" style="font-weight: 600;">Périodicité</label>
                        <validation-provider #default="{ errors }" name="periodicity" 
                          rules="required">
                          <v-select
                            :clearable="false"
                            :deselectFromDropdown="true"
                            :closeOnSelect="true"
                            :multiple="false"
                            id="periodicity"
                            :state="errors.length > 0 ? false : null"
                            v-model="leaseForm.periodicityId"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="
                              periodicitiesList.map(elem => {
                                return { label: elem.label, value: elem.id }
                              })"
                            :reduce="elem => elem.value"
                          >
                            <template v-slot:no-options>
                              <template> Aucune période trouvé </template>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3" class="py-0 mb-1">
                      <b-form-group>
                        <label label-for="paymentDay" style="font-weight: 600;">Jour paiement</label>
                        <validation-provider #default="{ errors }" name="paymentDay" 
                          rules="required">
                          <v-select
                            :deselectFromDropdown="true"
                            :closeOnSelect="true"
                            :multiple="false"
                            id="paymentDay"
                            :state="errors.length > 0 ? false : null"
                            v-model="leaseForm.paymentDay"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="
                              paymentDayList.map(elem => {
                                return elem 
                              })
                            "
                          >
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>


              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1">

                  <h3>Loyer et Charges</h3>
                  <b-row class="my-0">
                    <b-col cols="9" class="py-0 mb-1">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="rentHcHt"
                        >
                          <label label-for="rentHcHt" style="font-weight: 600;">Loyer Hors Charges Hors Taxes</label>
                          <b-form-input
                            id="rentHcHt"
                            v-model="leaseForm.rentHcHt"
                            :state="errors.length > 0 ? false : null"
                            placeholder="1000,00"
                            class="not-autocomplete"
                            autocomplete="nope" aria-autocomplete="nope"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3" class="py-0 mb-1">
                      <b-form-group>
                        <label label-for="paymentDay" style="font-weight: 600;">TVA</label>
                        <validation-provider #default="{ errors }" name="tvaRentId" 
                          rules="required">
                          <v-select
                            :clearable="false"
                            :deselectFromDropdown="true"
                            :closeOnSelect="true"
                            :multiple="false"
                            id="tvaRentId"
                            :state="errors.length > 0 ? false : null"
                            v-model="leaseForm.tvaRentId"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="
                              referencielTVAsList.map(elem => {
                                return { label: elem.label, value: elem.id }
                              })"
                            :reduce="elem => elem.value"
                          >
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="my-0">
                    <b-col cols="12" class="py-0 mb-1">
                      <b-form-group>
                        <label label-for="chargesPackage" style="font-weight: 600;">Charges</label>
                        <b-form-radio-group
                          id="chargesPackage"
                          v-model="leaseForm.isChargesPackage"
                          name="chargesPackage"
                          :options="chargesPackageOptions"
                          text-field="label"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="my-0">
                    <b-col cols="9" class="py-0 mb-1">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="chargeHt"
                        >
                          <label label-for="chargeHt" style="font-weight: 600;">Montant des charges</label>
                          <b-form-input
                            id="chargeHt"
                            v-model="leaseForm.chargeHt"
                            :state="errors.length > 0 ? false : null"
                            placeholder="1000,00"
                            class="not-autocomplete"
                            autocomplete="nope" aria-autocomplete="nope"
                          />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3" class="py-0 mb-1">
                      <b-form-group>
                        <label label-for="paymentDay" style="font-weight: 600;">TVA</label>
                        <validation-provider #default="{ errors }" name="tvaChargeId" 
                          rules="required">
                          <v-select
                            :clearable="false"
                            :deselectFromDropdown="true"
                            :closeOnSelect="true"
                            :multiple="false"
                            id="tvaChargeId"
                            :state="errors.length > 0 ? false : null"
                            v-model="leaseForm.tvaChargeId"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="
                              referencielTVAsList.map(elem => {
                                return { label: elem.label, value: elem.id }
                              })"
                            :reduce="elem => elem.value"
                          >
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="9" class="py-0 mb-1">
                        <validation-provider name="propertyTaxProvisioned">
                          <label label-for="chargeHt" style="font-weight: 600;">Taxe foncière de référence</label>
                            <b-form-input type="number" class="mb-0" id="propertyTaxProvisioned" v-model="leaseForm.propertyTaxProvisioned"></b-form-input>
                        </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <div class="bg-light-primary p-1 rounded">
                    <h4>Simulation des totaux d'une quittance</h4>
                    <div v-if="leaseForm.periodicityId>0 && leaseForm.startDate && leaseForm.startDate!==leaseForm.endDate">
                      <div class="d-flex w-100" id="simulation-loyer">
                        <div  class="text-left flex">Loyer du {{ computedLeaseDateFrom }} au {{ computedLeaseDateTo }} </div>
                        <div class="text-right">{{ computedRentHcHt }} €</div>
                      </div>
                      <div class="d-flex w-100" id="simulation-charge">
                        <div class="text-left flex">Provision pour charges</div>
                        <div class="text-right">{{ computedChargeHt }} €</div>
                      </div>
                      <div class="d-flex w-100 mt-2 border-bottom-secondary" id="simulation-charge">
                        <div class="text-left flex">Sous-Total HT</div>
                        <div class="text-right"> {{ computedSubTotalRent }} €</div>
                      </div>
                      <div class="d-flex w-100 border-bottom border-grey" id="simulation-charge">
                        <div class="text-left flex">Montant TVA</div>
                        <div class="text-right">{{ computedTotalTavRent }} €</div>
                      </div>
                      <div class="d-flex w-100 border-bottom" id="simulation-charge">
                        <div class="text-left flex font-weight-bold">Total TTC</div>
                        <div class="text-right font-weight-bold">{{ computedTotalTtcRent }} €</div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="p-2 text-center">
                        Veuillez indiquer une date de début et de fin de bail ainsi que la périodicité
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <h4>Activer la révision du loyer</h4>
                  <b-form-group
                  >
                    <b-form-checkbox
                      v-model="leaseForm.isRentReview"
                      class="mr-0 mt-50 font-weight-bold"
                      name="is-rtl"
                      switch
                      inline
                    >Activer la révision du loyer</b-form-checkbox>
                  </b-form-group>
                  <label>En activant cette option, le montant du loyer sera révisé automatiquement, chaque période de révision.</label>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="subscriptReferenceRentReview" style="font-weight: 600;">Type de révision</label>
                    <b-form-radio-group
                      id="subscriptReferenceRentReview"
                      v-model="leaseForm.isSubscriptReferenceRentReview"
                      name="subscriptReferenceRentReview"
                      :options="subscriptReferenceRentReviewOptions"
                      text-field="label"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="rentReviewSubScriptType" style="font-weight: 600;">Type d'indice</label>
                    <validation-provider #default="{ errors }" name="rentReviewSubScriptType" 
                      rules="required">
                      <v-select
                        :clearable="false"
                        :deselectFromDropdown="true"
                        :closeOnSelect="true"
                        :multiple="false"
                        id="rentReviewSubScriptType"
                        :state="errors.length > 0 ? false : null"
                        v-model="leaseForm.rentReviewSubScriptType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="reviewSubScriptTypeList"
                        :reduce="elem => elem.value"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="rentReviewSubScript" style="font-weight: 600;">Indice</label>
                    <validation-provider #default="{ errors }" name="rentReviewSubScript" 
                      rules="required">
                      <v-select
                        :deselectFromDropdown="true"
                        :closeOnSelect="true"
                        :multiple="false"
                        id="rentReviewSubScript"
                        :state="errors.length > 0 ? false : null"
                        v-model="leaseForm.rentReviewSubScript"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rentReviewSubScriptList"
                        :reduce="elem => elem.value"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="py-0 mb-1">
                  <b-form-group>
                    <label label-for="rentReviewPeriodicity" style="font-weight: 600;">Périodicité de révision</label>
                    <validation-provider #default="{ errors }" name="rentReviewPeriodicity" 
                      rules="required">
                      <v-select
                        :deselectFromDropdown="true"
                        :closeOnSelect="true"
                        :multiple="false"
                        id="rentReviewPeriodicity"
                        :state="errors.length > 0 ? false : null"
                        v-model="leaseForm.rentReviewPeriodicity"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rentReviewPeriodicityList"
                        :reduce="elem => elem.value"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="my-0">
                <b-col cols="6" class="py-0 mb-1">
                  <b-row class="my-0">
                    <b-col cols="12" class="py-0 mb-1">
                      <h3>Première quittance</h3>
                      <span>Si la première période de votre bail ne correspond pas à un période courante, précisez ici la date de fin de la première quittance.</span>
                    </b-col>
                  </b-row>
                  <b-form-group>
                    <label label-for="endDateFirstQuittance" style="font-weight: 600;">Date de fin de la première quittance</label>
                    <validation-provider
                      #default="{ errors }"
                      name="endDateFirstQuittance"
                    >
                    <!-- 
                        @update="setEndDateFirstQuittance" -->
                      <date-range-picker
                        ref="pickerEndDateFirstQuittance"
                        id="endDate"
                        style="width: 100%"
                        :state="errors.length > 0 ? false : null"
                        :show-week-numbers="false"
                        :timePicker="false"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="endDateFirstQuittance"
                        :single-date-picker="true"
                        :auto-apply="true"
                      >
                      </date-range-picker>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col cols="6" class="py-0 mb-1">
                  <div class="bg-light-primary p-1 rounded">
                    <h4>Simulation de la première quittance</h4>
                    <div class="d-flex w-100" id="simulation-loyer">
                      <div class="text-left flex">Loyer du {{ computedLeaseDateFromFirstQuittance }} au {{ computedLeaseDateToFirstQuittance }}</div>
                      <div class="text-right">{{ computedFirstLeaseRent }}</div>
                    </div>
                    <div class="d-flex w-100" id="simulation-charge">
                      <div class="text-left flex">Provision pour charges</div>
                      <div class="text-right"> {{ computedFirstLeaseCharge }}</div>
                    </div>

                    <div class="d-flex w-100 mt-2 border-bottom-secondary" id="simulation-charge">
                      <div class="text-left flex">Sous-Total HT</div>
                      <div class="text-right"> {{ computedFirstLeaseSubTotalRent }} €</div>
                    </div>
                    <div class="d-flex w-100 border-bottom border-grey" id="simulation-charge">
                      <div class="text-left flex">Montant TVA</div>
                      <div class="text-right">{{ computedFirstLeaseTotalTavRent }} €</div>
                    </div>
                    <div class="d-flex w-100 border-bottom" id="simulation-charge">
                      <div class="text-left flex font-weight-bold">Total TTC</div>
                      <div class="text-right font-weight-bold">{{ computedFirstLeaseTotalTtcRent }} €</div>
                    </div>

                  </div>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem;"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="leaseForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="archiveLeaseLocal(leaseForm.id, leaseForm.name)"
                >
                    <feather-icon icon="ArchiveIcon" /> 
                </b-button>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="leaseForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ leaseForm.id ? 'Modifier' : 'Ajouter' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>  


    
  </div>
</template>
<script>
var convert = require('xml-js');


import axios from 'axios'
import {debounce} from 'lodash';
import Autocomplete from 'vuejs-auto-complete'
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

import { calcFirstLeaseRent, calcFirstLeaseCharge, calcLeaseRent, calcLeaseCharge, calcSubTotalHt, calcTav, calcTotalTtc } from '@/types/api-orisis/library/LeaseOperations.ts'

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

const config = {
  direction: 'ltr',
  format: 'dd/mm/yyyy',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Période personnalisée',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  firstDay: 0
}
var defaultRange = {
  'Ce mois-ci': [
    new Date(
      dayjs()
        .startOf('month')
        .hour(12)
    ),
    new Date(dayjs().endOf('month'))
  ],
  'Ce trimestre': [
    new Date(
      dayjs()
        .startOf('quarter')
        .hour(12)
    ),
    new Date(dayjs().endOf('quarter'))
  ],
  'Cette annéee': [
    new Date(
      dayjs()
        .startOf('year')
        .hour(12)
    ),
    new Date(dayjs().endOf('year'))
  ],
  'Année prochaine': [
    new Date(
      dayjs()
        .add(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .add(1, 'year')
        .endOf('year')
    )
  ],
  'Année dernière': [
    new Date(
      dayjs()
        .subtract(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .subtract(1, 'year')
        .endOf('year')
    )
  ]
}
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      scrolled: false,
      tokenEudonet:"",
      searchProperty:"",
      searchLandlord:"",
      searchLandlordContract:"",
      searchTenant:"",
      searchTenantContract:"",
      autocompletePropertyData:[],
      autocompleteLandlordData:[],
      autocompleteLandlordContractData:[],
      autocompleteTenantData:[],
      autocompleteTenantContractData:[],
      leaseDuration:"",

      // leaseTypeList:[
      //   "Bail à ferme",
      //   "Bail à loyer",
      //   "Bail commercial",
      //   "Bail de bureaux",
      //   "Bail de colocation",
      //   "Bail de location meublée",
      //   "Bail de location saisonnière",
      //   "Bail de parking ou garage",
      //   "Bail de rénonvation",
      //   "Bail de résidence principale",
      //   "Bail étudiant", 
      //   "Bail mixte",
      //   "Contrat de bail de résidence secondaire",
      //   "Contrat de sous-location"
      // ],
      paymentDayList : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
      tvaList : [
        { label: 'Exonéré', value: 0 },
        { label: '20 %', value: 1 }
      ],
      chargesPackageOptions : [
        { label: 'Provisions pour charges', value: false },
        { label: 'Forfait de charges', value: true }
      ],
      subscriptReferenceRentReviewOptions : [
        { label: 'Indice de référence', value: true },
        { label: 'Pourcentage', value: false }
      ],
      reviewSubScriptTypeList:[
        { label: 'ICC (Indice du Cout de la Construction)', value: "ICC" },
        { label: 'ILC (Indice des Loyers Commerciaux)', value: "ILC" },
        { label: 'ILAT (Indice des Loyers des Activités Tertiaires', value: "ILAT" }
      ],
      rentReviewSubScriptList:[],
      rentReviewPeriodicityList:[
        { label: 'Tous les ans', value: 1 },
        { label: 'Tous les 3 ans', value: 3 }
      ],
      
      required,
      url,
      email,
      displayMore: false,
      dataOrigine:{},
      leaseForm:{},
      leaseType:{},
      startDate:{
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      },
      endDate:{
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      },
      endDateFirstQuittance : {
        startDate: dayjs().endOf('month').format("YYYY-MM-DD"),
        endDate: dayjs().endOf('month').format("YYYY-MM-DD"),
      },
      defaultRange: defaultRange,
      config: config,
      calcFirstLeaseRent,
      calcFirstLeaseCharge,
      calcLeaseRent,
      calcLeaseCharge,
      calcSubTotalHt,
      calcTav, 
      calcTotalTtc,
    }
  },
  created(){
    this.popupLeaseEvent(this.id)
    this.getTokenEudonet();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    ...mapActions([
      "createLease",
      "fetchLease",
	    "updateLease",
      "archiveLease",
      "fetchCollaboratorsList",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll("#nav-lease ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");
      });

      elements = document.querySelectorAll('.not-autocomplete');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupLeaseEvent(id) { 
      this.fetchCollaboratorsList();
      if (id != '') {
        this.fetchLease(id).then(res => {
          console.log("res>",res)
          this.leaseForm = res
          this.leaseForm.leaseTypeId = res.leaseType.id
          delete this.leaseForm.leaseType
          this.leaseForm.periodicityId = res.periodicity.id
          delete this.leaseForm.periodicity
          this.leaseForm.propertyTypeId = res.propertyType.id
          delete this.leaseForm.propertyType
          this.leaseForm.tvaChargeId = res.tvaCharge.id
          delete this.leaseForm.tvaCharge
          this.leaseForm.tvaRentId = res.tvaRent.id
          delete this.leaseForm.tvaRent
          // this.leaseForm.latitude = res.latitude ? res.latitude : 1
          // this.leaseForm.longitude = res.longitude ? res.longitude : 2
          // this.leaseForm.companyId = res.company.id
          // this.leaseForm.collaboratorIds = this.leaseForm.collaborators.map(
          //   collaborator => collaborator.id
          // )
          delete this.leaseForm.invoices
          this.dataOrigine=JSON.stringify(this.leaseForm)
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      
      this.leaseForm = {
        id: 0,
        status:0,
        label: null,
        codeCRO: null,
        propertyId : 0,
        propertyArea : null,
        propertyAddress : null,
        propertyAddressComplement : null,
        propertyZipCode : null,
        propertyCity : null,
        propertyCountry : null,
        propertyTaxProvisioned : 0,
        propertyLatitude : 0,
        propertyLongitude : 0,
        landlordId:0,
        landlordName:null,
        landlordAddress:null,
        landlordAddressComplement:null,
        landlordZipCode:null,
        landlordCity:null,
        landlordCountry:null,
        landlordContractId: 0,
        landlordContractFirstName: null,
        landlordContractLastName: null,
        landlordContractEmail: null,
        landlordContractPhone: null,
        tenantId: 0,
        tenantName:null,
        tenantAddress:null,
        tenantAddressComplement:null,
        tenantZipCode:null,
        tenantCity:null,
        tenantCountry:null,
        tenantContractId: 0,
        tenantContractFirstName: null,
        tenantContractLastName: null,
        tenantContractEmail: null,
        tenantContractPhone: null,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        duration:0,
        paymentDay:0,
        rentHcHt:0,
        tvaRentId:0,
        isAdvancePayment:true,
        isChargesPackage:true,
        chargeHt:0,
        tvaChargeId:0,
        securityDeposit:0,
        isRentReview:true,
        isSubscriptReferenceRentReview:true,
        isPercentageRentReview:true,
        rentReviewSubScriptType:"",
        rentReviewSubScript:"",
        rentReviewPeriodicity:"",
        startDateFirstReceipt:new Date().toISOString(),
        subscriptFirstReceipt:"",
        periodicityId:0,
        propertyTypeId:0,
        leaseTypeId:0,
      }
      this.dataOrigine=JSON.stringify(this.leaseForm)
    },
    displayFormComplement(){
      this.displayMore = !this.displayMore
      this.$nextTick(() => {
        this.deteleAutocomplete()
      })
    },
    create (e) {
      e.preventDefault()
      this.$refs.formCreateLease
        .validate() 
        .then(success1 => {
          if (success1) {
            if (this.leaseForm.id) 
              this.updateLease(this.leaseForm)
            else 
              this.createLease(this.leaseForm)
            
            this.$nextTick(() => {
              this.$tabs.close({to : "/leases"})
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTokenEudonet(){
      console.log("getTokenEudonet")
      let _this = this
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=authenticate`
      axios
        .get(url)
        .then(res => {
          console.log("tokenEudonet success", res.data)
          this.tokenEudonet = res.data;
        })
        .catch(err => {
          // console.log("autocompleteLandlord error", err)
        })
    },
    getDataInseeILC(){
      // Get Indice ILC
      this.leaseForm.rentReviewSubScript=null
      let _this = this
      let urlILC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001532540`
      axios
        .get(urlILC,{
            headers: {
              Authorization: `Bearer a25773ab-5291-3394-b645-4bf798b31e7f`
            }
          })
        .then(res => {
          var result = convert.xml2json(res.data, {ignoreComment: true, alwaysChildren: true});
          _this.rentReviewSubScriptList = JSON.parse(result).elements[0].elements[1].elements[0].elements.map(elem => {
              return { label: elem.attributes.TIME_PERIOD+' - '+elem.attributes.OBS_VALUE, value: elem.attributes.OBS_VALUE }
            })
        })
        .catch(err => {
          console.log("insee error", err)
        })
    },
    getDataInseeILAT(){
      // Get Indice ILAT
      this.leaseForm.rentReviewSubScript=null
      let _this = this
      let urlILAT = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/001617112`
      axios
        .get(urlILAT,{
            headers: {
              Authorization: `Bearer a25773ab-5291-3394-b645-4bf798b31e7f`
            }
          })
        .then(res => {
          var result = convert.xml2json(res.data, {ignoreComment: true, alwaysChildren: true});
          _this.rentReviewSubScriptList = JSON.parse(result).elements[0].elements[1].elements[0].elements.map(elem => {
              return { label: elem.attributes.TIME_PERIOD+' - '+elem.attributes.OBS_VALUE, value: elem.attributes.OBS_VALUE}
            })
        })
        .catch(err => {
          console.log("insee error", err)
        })
    },
    getDataInseeICC(){
      // Get Indice ICC
      this.leaseForm.rentReviewSubScript=null
      let _this = this
      let urlICC = `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/000008630`
      axios
        .get(urlICC,{
            headers: {
              Authorization: `Bearer a25773ab-5291-3394-b645-4bf798b31e7f`
            }
          })
        .then(res => {
          var result = convert.xml2json(res.data, {ignoreComment: true, alwaysChildren: true});
          _this.rentReviewSubScriptList = JSON.parse(result).elements[0].elements[1].elements[0].elements.map(elem => {
              return { label: elem.attributes.TIME_PERIOD+' - '+elem.attributes.OBS_VALUE, value: elem.attributes.OBS_VALUE }
            })
        })
        .catch(err => {
          console.log("insee error", err)
        })
    },
    // Autocomplete Landlord from eudonet
    autocompleteLandlord: debounce(function(indexSource){
      let _this = this
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=searchCompany&q=${this.searchLandlord}&token=${this.tokenEudonet}`

      console.log("autocompleteLandlord", url)
      axios
        .get(url)
        .then(res => {
          console.log("autocompleteLandlord success", res.data)
          this.autocompleteLandlordData = res.data;
        })
        .catch(err => {
          console.log("autocompleteLandlord error", err)
        })

    }, 500),
    autocompleteLandlordSelected (item) {
      this.leaseForm.landlordName=item.Fields[0].Value
      this.leaseForm.landlordAddress=item.Fields[1].Value
      this.leaseForm.landlordAddressComplement=item.Fields[2].Value
      this.leaseForm.landlordZipCode=item.Fields[3].Value
      this.leaseForm.landlordCity=item.Fields[4].Value
      this.leaseForm.landlordCountry=item.Fields[5].Value
    },
    autocompleteLandlordContract: debounce(function(indexSource){
      let _this = this
      // let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairByWorkspaceId`
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=searchCompany&q=${this.searchLandlord}&token=${this.tokenEudonet}`
      axios
        .get(url)
        .then(res => {
          // console.log("autocompleteLandlordContract success", res.data)
          this.autocompleteLandlordContractData = res.data;
        })
        .catch(err => {
          // console.log("autocompleteLandlordContract error", err)
        })

    }, 500),
    autocompleteLandlordContractSelected (item) {
      this.leaseForm.landlordName=item.Fields[0].Value
      this.leaseForm.landlordAddress=item.Fields[1].Value
      this.leaseForm.landlordAddressComplement=item.Fields[2].Value
      this.leaseForm.landlordZipCode=item.Fields[3].Value
      this.leaseForm.landlordCity=item.Fields[4].Value
      this.leaseForm.landlordCountry=item.Fields[5].Value
    },


    // Autocomplete Tenant from eudonet
    autocompleteTenant: debounce(function(indexSource){
      let _this = this
      // let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairByWorkspaceId`
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=searchCompany&q=${this.searchTenant}&token=${this.tokenEudonet}`
      axios
        .get(url)
        .then(res => {
          // console.log("autocompleteTenant success", res.data)
          this.autocompleteTenantData = res.data;
        })
        .catch(err => {
          // console.log("autocompleteTenant error", err)
        })

    }, 500),
    autocompleteTenantSelected (item) {
      this.leaseForm.tenantName=item.Fields[0].Value
      this.leaseForm.tenantAddress=item.Fields[1].Value
      this.leaseForm.tenantAddressComplement=item.Fields[2].Value
      this.leaseForm.tenantZipCode=item.Fields[3].Value
      this.leaseForm.tenantCity=item.Fields[4].Value
      this.leaseForm.tenantCountry=item.Fields[5].Value
    },
    autocompleteTenantContract: debounce(function(indexSource){
      let _this = this
      // let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairByWorkspaceId`
      let url = `https://dev.lhotellier.orisis.fr/api-eudonet.php?request=searchCompany&q=${this.searchLandlord}&token=${this.tokenEudonet}`
      axios
        .get(url)
        .then(res => {
          // console.log("autocompleteTenantContract success", res.data)
          this.autocompleteTenantContractData = res.data;
        })
        .catch(err => {
          // console.log("autocompleteTenantContract error", err)
        })
    }, 500),
    autocompleteTenantContractSelected (item) {
      this.leaseForm.tenantName=item.Fields[0].Value
      this.leaseForm.tenantAddress=item.Fields[1].Value
      this.leaseForm.tenantAddressComplement=item.Fields[2].Value
      this.leaseForm.tenantZipCode=item.Fields[3].Value
      this.leaseForm.tenantCity=item.Fields[4].Value
      this.leaseForm.tenantCountry=item.Fields[5].Value
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function(indexSource){
      console.log("autocompleteAddressSource")
      let _this = this
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.leaseForm.propertyAddress}&autocomplete=0`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.autocompletePropertyData = data.features;
        })
    }, 500),
    autocompleteAddressSelected (item) {
      console.log(item)
      this.leaseForm.propertyAddress=item.properties.name
      this.leaseForm.propertyCity=item.properties.city
      this.leaseForm.propertyZipCode=item.properties.postcode 
      this.leaseForm.propertyLatitude=item.geometry.coordinates[1] 
      this.leaseForm.propertyLongitude=item.geometry.coordinates[0]      
    },
    setStartDate (dates) {
      this.leaseForm.startDate = new Date(dates.startDate).toISOString()
      this.endDateFirstQuittance = {
        startDate: dayjs(dates.startDate).endOf('month').format("YYYY-MM-DD"),
        endDate: dayjs(dates.startDate).endOf('month').format("YYYY-MM-DD"),
      },
      console.log("setStartDate", dates, this.endDateFirstQuittance)
      this.leaseDuration = Math.round(dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true))+ " " + (dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true)>=2 ? 'ans' : 'an')
    },
    setEndDateFirstQuittance (dates) {
      // this.endDateFirstQuittance = {
      //   startDate: dayjs(dates.startDate).endOf('month').format("DD/MM/YYYY"),
      //   endDate: dayjs(dates.startDate).endOf('month').format("DD/MM/YYYY"),
      // }
    },
    setEndDate (dates) {
      this.leaseForm.endDate = new Date(dates.startDate).toISOString()
      if( dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true)<1){
        if(this.isInt(dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true))){
          this.leaseDuration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true)+' mois'
          this.leaseForm.duration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true)
          this.leaseForm.durationUnit = 'month'
        }else{
          this.leaseDuration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'day', true)+' jour(s)'
          this.leaseForm.duration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'day', true)
          this.leaseForm.durationUnit = 'day'
        }
      }else{
        if(this.isInt(dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true))){
          this.leaseDuration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true)+' an(s)'
          this.leaseForm.duration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'year', true)
          this.leaseForm.durationUnit = 'year'
        }else{
          if(this.isInt(dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true))){
            this.leaseDuration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true)+' mois'
            this.leaseForm.duration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'month', true)
            this.leaseForm.durationUnit = 'month'
          }else{
            this.leaseDuration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'day', true)+' jour(s)'
            this.leaseForm.duration = dayjs(this.leaseForm.endDate).diff(this.leaseForm.startDate, 'day', true)
            this.leaseForm.durationUnit = 'day'
          }
        }
      }
    },
    isInt(n){
      return Number(n) === n && n % 1 === 0; 
    },

    // Controls from
    archiveLeaseLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveLease(id);
            this.$tabs.close()
          }
        })
    },
    cancel(){
      if(this.dataOrigine==JSON.stringify(this.leaseForm)){
        this.$tabs.close()
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
           this.$tabs.close()
          }
        })
      }
    },
    newCompany () {
      this.$router.push({ path: '/directory/new-company/clients/5' })
      // if (this.leaseForm.id){
      //   this.$router.push({ name: 'new-company', params: { routeOrigine:'edit-lease/'+this.companyForm.id } })
      // }else{
      //   this.$router.push({ name: 'new-company', params: { routeOrigine:'new-lease/' } })
      // }
    },
    newCollaborator () {
      if (this.leaseForm.id){
        this.$router.push({ name: 'new-collaborator', params: { routeOrigine:'edit-lease/'+this.leaseForm.id } })
      }else{
        this.$router.push({ name: 'new-collaborator', params: { routeOrigine:'new-lease/' } })
      }
    },
  },
  watch: {
    "leaseForm.ownAddressLease": function (newVal, oldVal) {
      if(newVal){
        this.$nextTick(() => {
          this.deteleAutocomplete()
        })
      }
    },
    "leaseForm.rentReviewSubScriptType": function (newVal, oldVal) {
      if(newVal=="ILC"){
        this.getDataInseeILC()
      }else if(newVal=="ILAT"){
        this.getDataInseeILAT()
      }else if(newVal=="ICC"){
        this.getDataInseeICC()
      }
    }
  },
  computed: {
    leaseDates: {
      get () {
        return {
          startDate:null,
          endDate:null
        }
      },
      set (value) {
        this.leaseForm.startDate = value.startDate
        this.leaseForm.endDate = value.endDate
      }
    },
    computedLeaseDateFromFirstQuittance() {
      return dayjs(this.leaseForm.startDate).format("DD/MM/YYYY");
    },
    computedLeaseDateToFirstQuittance() {
      return dayjs(this.endDateFirstQuittance.startDate).format("DD/MM/YYYY");
    },
    computedLeaseDateFrom() {
        return dayjs(this.leaseForm.startDate).endOf('month').add(1, "day").format("DD/MM/YYYY");
    },
    computedLeaseDateTo() {
      // return this.periodicitiesList.find(elem => elem.id==this.leaseForm.periodicityId)
      let day = dayjs(this.leaseForm.startDate).endOf('month').add(1, "day");
      if (this.leaseForm.periodicityId && this.periodicitiesList.find(elem => elem.id==this.leaseForm.periodicityId).id == 1) {
        return dayjs(day).endOf('month').add(1, "day").format("DD/MM/YYYY");
      } else if(this.leaseForm.periodicity && this.periodicitiesList.find(elem => elem.id==this.leaseForm.periodicityId).id == 2) {
        return dayjs(day).add(3, "month").format("DD/MM/YYYY");
      } else if(this.leaseForm.periodicity && this.periodicitiesList.find(elem => elem.id==this.leaseForm.periodicityId).id == 3) {
        let day = dayjs(this.leaseForm.startDate).endOf('month').add(1, "day");
        return dayjs(day).add(1, "year").format("DD/MM/YYYY");
      }
    },
    computedFirstLeaseCharge() {
      if (this.leaseForm.chargeHt == 0) {
        return "0.00 €";
      } else {
        let diffDays = Math.ceil(dayjs(this.endDateFirstQuittance.startDate).diff(this.startDate.startDate, 'day', true)+1)
        return Math.round((diffDays/30)*this.leaseForm.chargeHt).toFixed(2);
      }
    },
    computedFirstLeaseRent() {
      if (this.leaseForm.rentHcHt == 0) {
        return "0.00 €";
      } else {
        let diffDays = Math.ceil(dayjs(this.endDateFirstQuittance.startDate).diff(this.startDate.startDate, 'day', true)+1)
        return Math.round((diffDays/30)*this.leaseForm.rentHcHt).toFixed(2);
      }
    },

    computedFirstLeaseSubTotalRent() {
      return (calcSubTotalHt(this.computedFirstLeaseRentHcHt, this.computedFirstLeaseChargeHt)).toFixed(2);
    },
    computedFirstLeaseTotalTavRent() {
      return Math.round(this.computedFirstLeaseRent*this.referencielTVAsList.find(elem=>elem.id==this.leaseForm.tvaRentId).value/100+this.computedFirstLeaseCharge* this.referencielTVAsList.find(elem=>elem.id==this.leaseForm.tvaChargeId).value/100).toFixed(2)
    },
    computedFirstLeaseTotalTtcRent() {
      return Math.round(Number(this.computedFirstLeaseRentHcHt) + Number(this.computedFirstLeaseChargeHt) + Number(this.computedFirstLeaseTotalTavRent)).toFixed(2)
    },
    computedFirstLeaseRentHcHt() {
      return (Math.round(this.computedFirstLeaseRent * 100) / 100).toFixed(2);
    },
    computedFirstLeaseChargeHt() {
      return (Math.round(this.computedFirstLeaseCharge * 100) / 100).toFixed(2);
    },


    computedSubTotalRent() {
      return (calcSubTotalHt(this.computedRentHcHt, this.computedChargeHt)).toFixed(2);
    },
    computedTotalTavRent() {
      return Math.round(this.leaseForm.rentHcHt*this.referencielTVAsList.find(elem=>elem.id==this.leaseForm.tvaRentId).value/100+this.leaseForm.chargeHt* this.referencielTVAsList.find(elem=>elem.id==this.leaseForm.tvaChargeId).value/100).toFixed(2)
    },
    computedTotalTtcRent() {
      return Math.round(Number(this.computedRentHcHt) + Number(this.computedChargeHt) + Number(this.computedTotalTavRent)).toFixed(2)
    },

    computedRentHcHt() {
      return (Math.round(this.leaseForm.rentHcHt * 100) / 100).toFixed(2);
    },
    computedChargeHt() {
      return (Math.round(this.leaseForm.chargeHt * 100) / 100).toFixed(2);
    },
    ...mapGetters([
      "isLoadingUser",
      "usersList",
      "isLoadingCollaboratorsList",
      "isLoadingLease",
      "collaboratorsList",
      "isLoadingCompany",
      "companiesList",
      "periodicitiesList",
      "propertyTypeList",
      "leaseTypeList",
      "referencielTVAsList"
    ])
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    VSwatches,
    DateRangePicker
  },
  directives: {
    Ripple,
    mask
  }
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  #nav-lease{
    white-space: nowrap;
      // border-right: 1px solid #333;
    border-right: 2px solid rgba(0,0,0,.05);
    -webkit-box-shadow: 0 0 25px 0 rgb(0 0 0 / 4%);
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 4%);
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: var(--body-color);
        text-decoration: none;
      }
      li a.current {
        color: var(--primary);
        font-weight: 600;
        position: relative;
        &:before{
          content:"";
          height: 100%;
          width: 3px;
          position: absolute;
          right:-14px;
          top:0px;
          
          background: linear-gradient(30deg,rgba(12, 53, 113 ,1),rgba(12, 53, 113, .5))!important;
          -webkit-box-shadow: 0 0 8px 0 rgba(12, 53, 113, .4)!important;
          box-shadow: 0 0 8px 0 rgba(12, 53, 113 ,.4)!important;
        }
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  #nav-lease ul {
    position: sticky;
    top: 0;
  }
}
</style>